<script lang="ts">
	import { getTranslate } from "../utils/getTranslate";
	import translations from "./HomepageArticles.translations.json";
	import arrowRightLong from "../../../core/assets/icons/arrow-right-long.svg?raw";
	import type { PublishedNews } from "../../../core/schema/News";
	import ArticleCard from "./ArticleCard.svelte";
	import { recentNewsAmountBlogHomepage } from "../../../core/schema/newsAmount.js";
	import { blogPathMap } from "../../../core/schema/paths/blogPathMap.js";
	import { getGlobalSettings } from "../getGlobalSettings.js";
	import Link from "./Link.svelte";

	export let articles: PublishedNews[];

	const { TENANT_LOCALE } = getGlobalSettings();
	const translate = getTranslate(translations);
</script>

<div class="flex flex-col-reverse gap-2 xl:grid xl:grid-cols-4">
	<div class="flex flex-col gap-8 lg:grid lg:grid-cols-3 lg:gap-2 xl:col-span-3">
		{#each articles.toReversed() as article, index}
			<ArticleCard {article} colStart={recentNewsAmountBlogHomepage - index} />
		{/each}
	</div>
	<div
		class="flex flex-col items-start justify-between pb-8 lg:col-span-3 lg:items-end lg:p-8 xl:col-span-1 xl:col-start-4"
	>
		<h2 class="m-0 p-0 text-center text-3xl lg:text-left">{translate("blog")}</h2>
		<Link
			asButton
			variant="primaryTransparent"
			href="/{blogPathMap[TENANT_LOCALE]}"
			icon={arrowRightLong}
			class="text-xs font-semibold uppercase tracking-widest"
		>
			{translate("otherBlogs")}
		</Link>
	</div>
</div>
